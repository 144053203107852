export enum VerificationStatus {
  infoSaved = "info_saved",
  submitted = "submitted",
  submittedWithDocument = "submitted_with_document",
  verifiedAutomatically = "verified_automatically",
  verifiedManually = "verified_manually",
  verifiedWithDocument = "verified_with_document",
  flaggedForReview = "flagged_for_review",
  flaggedForInternalReview = "flagged_for_internal_review",
  rejected = "rejected",
}
