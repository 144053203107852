import React, { useEffect, useState } from "react";
import { getIdentityVerificationStatus } from "store/api/apiSlice";
import { VerificationStatus } from "types/verification";
import { InfoType } from "../types";
import Loading from "./Loading";
import IdentityForm from "./IdentityForm";
import Message from "./Message";
import DocumentUpload from "./DocumentUpload";

const VERIFICATION_STATUS_PENDING = [
  VerificationStatus.infoSaved,
  VerificationStatus.submitted,
  VerificationStatus.submittedWithDocument,
];

const VERIFICATION_STATUS_WITH_ACTIVE_POLLING = [
  ...VERIFICATION_STATUS_PENDING,
  // TODO: Add syncronous event for document submission (like info_saved) so we can poll based on that instead of flagged_for_review
  VerificationStatus.flaggedForReview,
];

const VERIFICATION_STATUS_VERIFIED = [
  VerificationStatus.verifiedAutomatically,
  VerificationStatus.verifiedManually,
  VerificationStatus.verifiedWithDocument,
];

const VERIFICATION_STATUS_HELD = [
  VerificationStatus.flaggedForInternalReview,
  VerificationStatus.rejected,
];

const VERIFICATION_STATUS_DETERMINED = [
  ...VERIFICATION_STATUS_VERIFIED,
  ...VERIFICATION_STATUS_HELD,
];

interface Props {
  showInfo: (infoType: InfoType) => () => void;
}

const IdentityVerification: React.FC<Props> = ({ showInfo }) => {
  const [isActivelyVerifying, setIsActivelyVerifying] = useState(false);
  const { data: identityVerification, ...identityData } =
    getIdentityVerificationStatus({
      pollingInterval: isActivelyVerifying ? 5000 : undefined,
    });
  const verificationStatus = identityVerification?.currentStatus;

  useEffect(() => {
    setIsActivelyVerifying(
      VERIFICATION_STATUS_WITH_ACTIVE_POLLING.includes(verificationStatus)
    ); // poll for verification status if it's pending

    if (VERIFICATION_STATUS_VERIFIED.includes(verificationStatus)) {
      window.setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }, [verificationStatus]);

  if (identityData.isLoading) {
    return <Loading />;
  }

  if (
    // All static messaging is handled by the Message component
    VERIFICATION_STATUS_DETERMINED.includes(verificationStatus) ||
    VERIFICATION_STATUS_PENDING.includes(verificationStatus)
  ) {
    return <Message status={verificationStatus as VerificationStatus} />;
  }

  if (verificationStatus === VerificationStatus.flaggedForReview) {
    return <DocumentUpload />;
  }

  return <IdentityForm showInfo={showInfo} />;
};

export default IdentityVerification;
