import * as process from "process";

const enableDemoTriggers =
  process.env.ENABLE_DEMO_TRIGGERS === "true" &&
  process.env.RESET_ENV !== "production";

const socureSdkKey = process.env.SOCURE_SDK_KEY;

export default {
  enableDemoTriggers,
  socureSdkKey,
};
