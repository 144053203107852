export const API_PATH = "/api/v1";

export const API_URL = `https://www.getreset.co${API_PATH}`;

export const POLLING_INTERVAL = 60_000;

export const getHeaders = () => ({
  "Content-Type": "application/json",
  "X-CSRF-Token": (
    document.querySelector("meta[name='csrf-token']") as HTMLMetaElement
  ).content,
});

export const fetchWithCredentials = (
  url: RequestInfo,
  options: RequestInit = {}
) => {
  const { headers, ...restOptions } = options;
  return fetch(url, {
    headers: {
      ...getHeaders(),
      ...headers,
    },
    ...restOptions,
  });
};
